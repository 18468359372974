import * as Tone from "tone";

import bodysound1 from "/static/sounds/bodysound1.wav";
//const bodysound1 = new URL("/static/sounds/bodysound1.wav", import.meta.url);
import bodysound2 from "/static/sounds/bodysound2.wav";
import bodysound3 from "/static/sounds/bodysound3.wav";
import bodysound4 from "/static/sounds/bodysound4.wav";
import z1h1 from "/static/sounds/z1.H.1.wav";
import z1h2 from "/static/sounds/z1.H.2.wav";
import z1h3 from "/static/sounds/z1.H.3.wav";
import z1l1 from "/static/sounds/z1.L.1.wav";
import z1l2 from "/static/sounds/z1.L.2.wav";
import z1l3 from "/static/sounds/z1.L.3.wav";
import z1m1 from "/static/sounds/z1.M.1.wav";
import z1m2 from "/static/sounds/z1.M.2.wav";
import z1m3 from "/static/sounds/z1.M.3.wav";
import z2h1 from "/static/sounds/z2.H.1.wav";
import z2h2 from "/static/sounds/z2.H.2.wav";
import z2h3 from "/static/sounds/z2.H.3.wav";
import z2l1 from "/static/sounds/z2.L.1.wav";
import z2l2 from "/static/sounds/z2.L.2.wav";
import z2l3 from "/static/sounds/z2.L.3.wav";
import z2m1 from "/static/sounds/z2.M.1.wav";
import z2m2 from "/static/sounds/z2.M.2.wav";
import z2m3 from "/static/sounds/z2.M.3.wav";
import z3h1 from "/static/sounds/z3.H.1.wav";
import z3h2 from "/static/sounds/z3.H.2.wav";
import z3h3 from "/static/sounds/z3.H.3.wav";
import z3h4 from "/static/sounds/z3.H.4.wav";
import z3h5 from "/static/sounds/z3.H.5.wav";
import z3h6 from "/static/sounds/z3.H.6.wav";
import z3h7 from "/static/sounds/z3.H.7.wav";
import z3l1 from "/static/sounds/z3.L.1.wav";
import z3l2 from "/static/sounds/z3.L.2.wav";
import z3l3 from "/static/sounds/z3.L.3.wav";
import z3l4 from "/static/sounds/z3.L.4.wav";
import z3l5 from "/static/sounds/z3.L.5.wav";
import z3l6 from "/static/sounds/z3.L.6.wav";
import z3m1 from "/static/sounds/z3.M.1.wav";
import z3m2 from "/static/sounds/z3.M.2.wav";
import z3m3 from "/static/sounds/z3.M.3.wav";
import z3m4 from "/static/sounds/z3.M.4.wav";
import z3m5 from "/static/sounds/z3.M.5.wav";

/*
 * Sound Resources
 */
export const bodySounds = {
  1: new Tone.Player(bodysound1),
  2: new Tone.Player(bodysound2),
  3: new Tone.Player(bodysound3),
  4: new Tone.Player(bodysound4)
};
export const zoneSounds = {
  // Zone 1
  z1h1: new Tone.Player(z1h1),
  z1h2: new Tone.Player(z1h2),
  z1h3: new Tone.Player(z1h3),

  z1m1: new Tone.Player(z1m1),
  z1m2: new Tone.Player(z1m2),
  z1m3: new Tone.Player(z1m3),

  z1l1: new Tone.Player(z1l1),
  z1l2: new Tone.Player(z1l2),
  z1l3: new Tone.Player(z1l3),

  // Zone 2
  z2h1: new Tone.Player(z2h1),
  z2h2: new Tone.Player(z2h2),
  z2h3: new Tone.Player(z2h3),

  z2l1: new Tone.Player(z2l1),
  z2l2: new Tone.Player(z2l2),
  z2l3: new Tone.Player(z2l3),

  z2m1: new Tone.Player(z2m1),
  z2m2: new Tone.Player(z2m2),
  z2m3: new Tone.Player(z2m3),

  // Zone 3
  z3h1: new Tone.Player(z3h1),
  z3h2: new Tone.Player(z3h2),
  z3h3: new Tone.Player(z3h3),
  z3h4: new Tone.Player(z3h4),
  z3h5: new Tone.Player(z3h5),
  z3h6: new Tone.Player(z3h6),
  z3h7: new Tone.Player(z3h7),

  z3m1: new Tone.Player(z3m1),
  z3m2: new Tone.Player(z3m2),
  z3m3: new Tone.Player(z3m3),
  z3m4: new Tone.Player(z3m4),
  z3m5: new Tone.Player(z3m5),

  z3l1: new Tone.Player(z3l1),
  z3l2: new Tone.Player(z3l2),
  z3l3: new Tone.Player(z3l3),
  z3l4: new Tone.Player(z3l4),
  z3l5: new Tone.Player(z3l5),
  z3l6: new Tone.Player(z3l6)
};
