
export const Terminal = (function Terminal() {
	const containerNode = document.getElementById("buffer");
	const cursorNode = document.getElementById("cursor");
	const UPDATE_INTERVAL = 50; // ticker interval in ms
	// const width = cssVar("terminal-width"); // terminal width in px
	// const height = cssVar("terminal-height"); // terminal height in px
	// @HACK – make responsive! HMW get the result of CSS calc() in JS?
	// => guess: getComputedStyle
	const LINES = 26; // cssVar("terminal-lines"); // visible lines
	const COLS = 94; // cssVar("terminal-cols"); // chars per line
	const BUFFERSIZE = LINES * COLS;
	let queue = [];
	let _isUpdating = false; // Semaphore to suspend output while updating 
	let _isIdle = false;

	function init() {
		this.clear();
		_isIdle = true;
	}

	let lastTime = Date.now();
	function tick(frame) {
		if (Date.now() - lastTime > UPDATE_INTERVAL) {
			lastTime = Date.now();
			if ((queue.length > 1) && (!_isUpdating)) {
				printChar(queue.shift());
				if (queue.length == 0) {
					_isIdle = true;
				};
			} // Math.max(0, nextTime - new Date().getTime())
		}
	}

	function inject(text) {
		_isUpdating = true;
		let chars = [];
		if (typeof text === "string") {
			chars = text.split("");
		} else {
			chars = [...text];
		}
		queue = [...chars, ...queue]; // Alternative: queue.unShift(...chars)
		_isUpdating = false;
	}

	function print(text) {
		_isUpdating = true;
		let chars = [];
		if (typeof text === "string") {
			chars = text.split("");
		} else {
			chars = [...text];
		}
		queue.push(...chars);
		_isUpdating = false;
	}

	function printChar(char) {
		let el;
		if (char == "\n") {
			el = document.createElement("br");
		} else {
			el = document.createElement("span");
			if (char == " ") {
				char = "&nbsp;";
			}
			el.innerHTML = char;
		}
		containerNode.insertBefore(el, cursorNode);
		scroll();
	}

	function isIdle() {
		return (queue.length < 2);
	}

	function clear() {
		_isUpdating = true;
		containerNode.querySelectorAll(':not(#cursor)').forEach(el => el.remove());
		_isUpdating = false;
	}

	function scroll() {
		// containerNode.scrollHeight > containerNode.clientHeight
		// containerNode.scrollTop = containerNode.scrollHeight;
		cursorNode.scrollIntoView();
	}

	function prune() {
		const maxBufferLength = BUFFERSIZE * 4;
		let bufferContent = containerNode.querySelectorAll(':not(#cursor)');
		let overflow = bufferContent.length - maxBufferLength;
		if (overflow > 0) {
			// console.log("Pruning buffer...");
			bufferContent.forEach(el => {
				// if (--overflow > 0)
				// el.remove();
			});
		}
	}

	return {
		this: this,
		init: init,
		print: print,
		inject: inject,
		clear: clear,
		isIdle: isIdle,
		tick: tick,
	};

})();
