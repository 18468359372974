import { shuffle, pickRandom } from "./util";

export function* Citations() {
	const lines = [
		"we do not wish to penalise the machine for its inability to shine in beauty competitions, nor to penalise a man for losing in a race against an aeroplane",
		"the internal state of the machine may be q1, q2 or q3 choose at random between the values 3.12, 3.13, 3.14, 3.15, 3.16 with the probabilities of 0.05, 0.15, 0.55, 0.19, 0.06",
		"the nervous system is certainly not a discrete-state machine. a small error in the information about the size of a nervous impulse impinging on a neuron, may make a large difference to the size of the outgoing impulse",
		"here a corresponding phenomenon for minds, and is there one for machines? there does seem to be one for the human mind. can a machine be supercritical?",
		"events.forEach(event => { var _event$properties$mag, _event$properties$pla; let [_lat, _long, _depth] = event.geometry.coordinates; let _time = new Date(event.properties.time).toUTCString();",
		'let _mag = (_event$properties$mag = event.properties.mag) !== null && _event$properties$mag !== void 0 ? _event$properties$mag : 0; let _place = (_event$properties$pla = event.properties.place) !== null && _event$properties$pla !== void 0 ? _event$properties$pla : ""; o.events.push([_time, _lat, _long, _depth, _mag, _place]); });'
	];
	let randomLine = pickRandom(lines);
	yield ` ${randomLine} `; // return random line
};
