import { shuffle, pickRandom } from "./util";

export function* HistoricalEvents() {
	/*
	(Geschätzte) Angaben zum Erdbeben 1409
	Datum: 24. August 1409
	Momentmagnitude: 4.0
	Maximalintensität: IV
	Breite: 52.102822
	Länge: 11.401147
	Location: Magdeburger Börde
	*/
	const lines = [
		"SAT, 24 AUG 1409 52.102822 11.401147 * 4.0 Magdeburger Boerde, Brandenburg",
		"SOME DAY 1483 51.70 13.22 * 4.0 near Herzberg, Brandenburg",
		"NOV 1736 53.13 14.17 * 2.9 Stendell, Uckermark",
		"TUE, 29 JUN 1920 51.77 14.22 * 3.2 Cottbus, Brandenburg",
		"SAT, 15 SEP 1984 51.55 13.35 * 1.5 near Herzberg, Brandenburg"
	];
	let randomLines = shuffle(lines);
	let randomLine = pickRandom(randomLines);
	yield ` ${randomLine} `; // return random line
}
