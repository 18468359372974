import "./app.css";
import { Credits } from "./credits";
import { Citations } from "./citations";
import { HistoricalEvents } from "./historicalevents";
import { Quakes } from "./quakes";
import { Terminal } from "./terminal";
import { Composer } from "./composer";

const App = (function App() {
  const QUAKE_UPDATE_INTERVAL = 1000 * 60 * 10;
  const CREDITS_INTERVAL = 1000 * 60 * 30;
  let frame = 0;

  return {
    this: this,
    start: start
  };

  function start() {
    Quakes.init(Terminal, Composer);
    Terminal.init(Quakes);
    Composer.init(Quakes);
    showCredits();
    setInterval(Quakes.update, QUAKE_UPDATE_INTERVAL);
    setInterval(showCredits, CREDITS_INTERVAL);
    requestAnimationFrame(loop);
  }
  function showCredits() {
    Terminal.clear();
    Terminal.inject(Credits().get());
  }

  function loop() {
    requestAnimationFrame(loop);
    frame++;
    Terminal.tick(frame);
    Composer.tick(frame);
    Quakes.tick(frame);

    if (frame % 3600 === 0) {
      if (Math.random() > 0.23) {
        Terminal.inject(HistoricalEvents().next().value);
      }
      if (Math.random() > 0.5) {
        Terminal.inject(Citations().next().value);
      }
      if (Math.random() > 0.62) {
        Terminal.inject(Citations().next().value);
      }
      if (Math.random() > 0.9) {
        Terminal.inject(Citations().next().value);
      }
    }
  }
})();

//document.addEventListener("DOMContentLoaded", (event) => {
App.start();
//});
document.addEventListener("click", (event) => {
  Composer.startSound();
});
