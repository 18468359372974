import { HistoricalEvents } from "./historicalevents";
import { Terminal } from "./terminal";
import { uniq } from "./util";

export const Quakes = {
  _isReady: false,
  _isUpdating: false,
  sources: {
    usgs: {
      // https://earthquake.usgs.gov/fdsnws/event/1/#methods
      url:
        "https://earthquake.usgs.gov/fdsnws/event/1/query?" +
        "format=geojson&limit=200&orderby=time",
      // "format=geojson&limit=100&orderby=time&includeallmagnitudes",
      format: "geojson"
    },
    emsc: {
      url:
        "https://www.seismicportal.eu/fdsnws/event/1/query?" +
        "format=json&limit=200&orderby=time",
      format: "geojson"
    }
  },
  events: [],
  _events: {},
  currentEvent: undefined,
  buffer: "",
  terminal: undefined,
  composer: undefined,
  composerUpdate: undefined,
  lastTime: Date.now(),

  init(terminal, composer) {
    this._isReady = false;
    this.buffer = "";
    this.events = [];
    this.terminal = terminal;
    this.composer = composer;
    this.update();
  },

  tick(frame) {
    if (this.terminal.isIdle()) {
      this.output();
    }
  },

  eventToString(event) {
    return event.join(" ") + " ";
  },

  update() {
    this._isUpdating = true;
    // @TODO safeguard against loading errors
    fetch(this.sources.emsc.url)
      .then((response) => response.json())
      .then((json) => this.parseGeojson(json, this))
      .then(() => {
        this.currentEvent = 0;
        // @TODO: limit number of events
        const stream = this.events.reduce((acc, event) => acc + this.eventToString(event), '');
        this.buffer = stream;
        this._isUpdating = false;
        this._isReady = true;
      });
  },

  output() {
    let eventString = "";
    if (this._isReady && !this._isUpdating) {
      eventString = this.getNextEvent().join(" ") + " ";
    } else {
      eventString = HistoricalEvents().next().value;
    }
    Terminal.print(eventString);
  },

  getNextEvent() {
    if (this._isReady) {
      let event = this.events[this.currentEvent];
      this.currentEvent++;
      if (this.currentEvent > this.events.length) {
        this.currentEvent = 0;
      }
      return event;
    } else {
      return [];
    }
  },

  getEvents() {
    if (this._isReady) {
      return this.events;
    } else {
      return [];
    }
  },

  isReady() {
    return this._isReady;
  },

  getAll() {
    if (this._isReady) {
      return this.buffer;
    } else {
      return ". ";
    }
  },
  parseGeojson(gjson, quakes) {
    /*
     * @HACK Mutates quakes!
     */
    var events = gjson.features;
    events.forEach((event) => {
      var _event$properties$mag, _event$properties$pla;
      let [_lat, _long, _depth] = event.geometry.coordinates;
      if (_depth < 0) _depth *= -1; // USGS measures depth from 0 upwards, EMSC downwards
      let _time = new Date(event.properties.time).toUTCString();
      let _mag = event.properties?.mag ?? 0;
      let _place = (event.properties?.place /* usgs */ ?? event.properties?.flynn_region /* emsc */ ) ?? '';
      quakes.events.push([_time, _lat, _long, _depth, _mag, _place]);
      quakes._events[_time] = [_lat, _long, _depth, _mag, _place];
    });
    quakes.events = uniq(quakes.events);
    return {};
  }
};
