/*
 * Helper Functions
 *
 */

export function cssVar(name, value) {
	if (name.substr(0, 2) !== "--") {
		name = `--${name}`;
	}
	if (value) {
		document.documentElement.style.setProperty(name, value);
	}
	return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export function shuffle(array) {
	let shuffledArray = [...array];
	// Fisher-Yates algorithm
	for (let i = shuffledArray.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1)); 
		// random index from 0 to i
		// swap elements array[i] and array[j] using "destructuring assignment" syntax
		// same can be written as:
		// let t = array[i]; array[i] = array[j]; array[j] = t
		[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray;
}

export function pickRandom(fromArrayOrObject) {
	// if (typeof (fromArrayOrObject) == "object") {
	return fromArrayOrObject[randomKey(fromArrayOrObject)];
	// } else {
	// return undefined;
	// }
}

export function randomKey(fromObject) {
	let keys = Object.keys(fromObject);
	return keys[~~(Math.random() * keys.length)];
}

export function haversine ([lat1, lon1], [lat2, lon2]) {
	// Math lib function names
	const [pi, asin, sin, cos, sqrt, pow, round] = [
		'PI', 'asin', 'sin', 'cos', 'sqrt', 'pow', 'round'
	]
	.map(k => Math[k]),

		// degrees as radians
		[rlat1, rlat2, rlon1, rlon2] = [lat1, lat2, lon1, lon2]
		.map(x => x / 180 * pi),

		dLat = rlat2 - rlat1,
		dLon = rlon2 - rlon1,
		radius = 6372.8; // km

	// km
	return round(
		radius * 2 * asin(
			sqrt(
				pow(sin(dLat / 2), 2) +
				pow(sin(dLon / 2), 2) *
				cos(rlat1) * cos(rlat2)
			)
		) * 100
	) / 100;
}

export function uniq(arr) {
	return Array.from(new Set(arr));
}