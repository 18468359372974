export function haversine([lat1, lon1], [lat2, lon2]) {
	// Math lib function names
	const [pi, asin, sin, cos, sqrt, pow, round] = [
		'PI', 'asin', 'sin', 'cos', 'sqrt', 'pow', 'round'
	]
		.map(k => Math[k]),
		// degrees as radians
		[rlat1, rlat2, rlon1, rlon2] = [lat1, lat2, lon1, lon2]
			.map(x => x / 180 * pi), dLat = rlat2 - rlat1, dLon = rlon2 - rlon1, radius = 6372.8; // km


	// km
	return round(
		radius * 2 * asin(
			sqrt(
				pow(sin(dLat / 2), 2) +
				pow(sin(dLon / 2), 2) *
				cos(rlat1) * cos(rlat2)
			)
		) * 100
	) / 100;
}


export function getBearing([lat1, lon1], [lat2, lon2]) {
    // const R = 6371e3; // metres
    let φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    let φ2 = lat2 * Math.PI / 180;
    let λ1 = lon1 * Math.PI / 180;
    let λ2 = lon2 * Math.PI / 180;
    // let Δφ = (lat2 - lat1) * Math.PI / 180;
    // let Δλ = (lon2 - lon1) * Math.PI / 180;
    // let a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    // let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // let distance = R * c; // in metres
    let y = Math.sin(λ2 - λ1) * Math.cos(φ2);
    let x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
    let θ = Math.atan2(y, x);
    let bearing = (θ * 180 / Math.PI + 360) % 360; // in degrees
    return bearing;
}
