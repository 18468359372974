export function Credits() {
	const credits = [
		"",
		"Franz John:",
		"",
		"Turing Tables",
		"An Untitled Composition for Tectonic Spaces",
		"",
		"In cooperation with",
		"Sascha Brossmann (graphics, sound composition, programming) &",
		"Ed Osborn (sound design).",
		"",
		"",
		"",
	];

	function get() {
		return credits.join("\n");
	}
	return {
		this: this,
		get: get
	};

}
