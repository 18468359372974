import * as Tone from "tone";
import { pickRandom } from "./util";
import { bodySounds, zoneSounds } from "./soundfiles";
import { haversine, getBearing } from "./haversine";

let bsActive = 0;
const bsLimit = 3;
let zsActive = 0;
const zsLimit = 16;
const zoneDimensions = [
  [3, 3, 3],
  [3, 3, 3],
  [7, 5, 6] // z3: H, M, L
];
let events = [];
let quakes;
const UPDATE_INTERVAL = 1000 * 60 * 5;
let lastTime = Date.now() - 1000 * 60 * 10;
let eventIndex = 0;

export const Composer = (function Composer() {
  function init(Quakes) {
    quakes = Quakes;
    Tone.loaded().then(() => {
      initSound();
      makeNoise();
      Tone.Transport.bpm.value = 30;
      Tone.Transport.start();
    });
  }

  function startSound() {
    Tone.start();
  }

  function tick(frame) {
    if (frame % 60) {
      if (quakes.isReady()) {
        updateEvents();
      }
    }
  }

  function updateEvents() {
    if (Date.now() - lastTime > UPDATE_INTERVAL) {
      lastTime = Date.now();
      console.log("Updating Composer");
      events = quakes.getEvents();
    }
  }

  return {
    this: this,
    init: init,
    tick: tick,
    startSound: startSound
  };
})();

// @HACK
function makeBodysound(time) {
  const bodySoundReverb = new Tone.Reverb({
    wet: 0.62,
    time: 20
  });

  let snd = pickRandom(bodySounds);
  if (bsActive < bsLimit) {
    try {
      bsActive++;
      // let length = snd.buffer.duration;
      snd.loop = false;
      snd.fadeIn = Math.random() + 0.1;
      snd.fadeOut = Math.random() * 2 + 0.5;
      snd.playbackRate = 1.1 - Math.random() * 0.2;
      let pan = 1.9 * Math.random() - 0.95;
      let vol = -12 * Math.random() - 30;
      let panVol = new Tone.PanVol(pan, vol).toDestination();
      snd
        .connect(panVol)
        .connect(bodySoundReverb)
        .start(Math.random() * 4);
      panVol.pan.rampTo(1.6 * Math.random() - 0.8, Math.random() * 1.5 + 0.8);
      snd.onstop = () => {
        bsActive--;
      };
    } catch (TypeError) {
      console.error(snd);
    }
  }
}

function makeNoise() {
  const bodySoundLoop = new Tone.Loop((time) => {
    if (Math.random() > 0.23) {
      makeBodysound();
    }
  }, "4n").start(1.5);
  const zoneSoundLoop = new Tone.Loop((time) => {
    if (Math.random() > 0.5) {
      makeZonesound();
    }
  }, "16n").start(2.5);
}

function makeZonesound(time) {
  const locations = {
    Exploratorium: [37.801817470042394, -122.39730508611473],
    Literaturhaus: [52.502302713239374, 13.326938473651229]
  };

  if (zsActive < zsLimit) {
    let event = events[eventIndex++];
    if (eventIndex > events.length) {
      eventIndex = 0;
    }
    let [eventTime, lat, long, depth, magnitude, loc] = event;
    let age = (Date.now() - Date.parse(eventTime)) / 3600000; // in hours
    let distance = haversine(locations.Literaturhaus, [lat, long]);
    let bearing = ~~getBearing(
      [52.502302713239374, 13.326938473651229],
      [lat, long]
    );
    if (distance <= 0) {
      distance = 0.1;
    }
    let distanceNorm = distance / 20020.74; // normalised distance
    if (!(magnitude > 0)) {
      magnitude = 1;
    }
    // console.log(`Distance: ${distance}, Bearing: ${~~bearing}°`);

    // Select zone
    let zone;
    /*
        if (lat > 30) { // North
            zone = 1;
        } else if (lat < -30) { // South
            zone = 2;
        } else { // Equatorial
            zone = 3;
        }
        */
    if (distanceNorm > 0.85) {
      // far away
      zone = 1;
    } else if (distanceNorm > 0.62) {
      // medium
      zone = 2;
    } else {
      // near
      zone = 3;
    }

    // Select layer
    function mapDepthToLayer(depth) {
      if (depth < 20) {
        return "h";
      } else if (depth < 60) {
        return "m";
      } else {
        return "l";
      }
    }
    let layer = mapDepthToLayer(depth);

    let index = 1;

    if (zone == 3) {
      if (layer == "h") {
        if (magnitude <= 2.5) index = 1;
        else if (magnitude <= 3.0) index = 2;
        else if (magnitude <= 3.5) index = 3;
        else if (magnitude <= 4.0) index = 4;
        else if (magnitude <= 4.5) index = 5;
        else if (magnitude <= 5.0) index = 6;
        else index = 7;
      }
      if (layer == "m") {
        if (magnitude <= 2.5) index = 1;
        else if (magnitude <= 3.5) index = 2;
        else if (magnitude <= 4.5) index = 3;
        else if (magnitude <= 5.5) index = 4;
        else index = 5;
      }
      if (layer == "l") {
        if (magnitude <= 2.5) index = 1;
        else if (magnitude <= 3.5) index = 2;
        else if (magnitude <= 4.5) index = 3;
        else if (magnitude <= 5.5) index = 4;
        else if (magnitude <= 6.0) index = 5;
        else index = 6;
      }
    } else {
      if (magnitude <= 2.5) index = 1;
      else if (magnitude <= 3.5) index = 2;
      else index = 3;
    }

    let dispatchKey = `z${zone}${layer}${index}`;
    let snd = zoneSounds[dispatchKey];
    console.log(dispatchKey);
    if (Math.random() > 0.8 && magnitude < 4.5) {
      snd = pickRandom(zoneSounds);
    }

    //  set the repetition interval (p_interval) (~ distance & magnitude)
    let interval = ~~(12000.0 * distance * Math.abs(magnitude - 2.0));
    // set volume equal to mediated percentage of magnitude & distance
    // assert: no quakes stronger than 8 richter!
    let volumeScale =
      Math.pow(magnitude / 8.0, 1.5) * 0.75 + (1 - distanceNorm) * 0.25;
    if (volumeScale > 1) volumeScale = 1;

    if (snd.state !== "started") {
      zsActive++;
      let pan = ((bearing - 180) / 180) * 0.9 + (0.1 - Math.random() * 0.2);
      let vol = 24 * volumeScale - 27;
      let panVol = new Tone.PanVol(pan, vol).toDestination();
      snd.playbackRate = 1.15 - Math.random() * 0.3;
      snd.fadeIn = Math.random() + 0.1;
      snd.fadeOut = Math.random() + 0.8;
      snd.connect(panVol).start(Math.random() * 4 * Math.abs(distanceNorm));
      panVol.pan.rampTo(2 * Math.random() - 1, Math.random() * 2 + 0.2);
      snd.onstop = () => {
        zsActive--;
      };
    }
  }
}

function initSound() {
  // some compression to keep the levels in check
  const mainCompressor = new Tone.Compressor({
    threshold: -18,
    ratio: 1.5,
    attack: 0.01,
    release: 0.3
  });
  const lowShelf = new Tone.Filter({
    type: "lowshelf",
    frequency: 25,
    Q: 1,
    gain: 1.5
  });
  const reverb = new Tone.Reverb({
    wet: 0.2,
    time: 50
  });
  Tone.Destination.chain(lowShelf, mainCompressor, reverb);
}
